import MyMap from "../../components/map/Map";

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full bg-gray-100">
      <div className="w-full max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
        <div className="w-full h-96 rounded-md shadow-md">
          <MyMap />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
