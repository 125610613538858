const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary p-4 mt-8">
      <div className="container mx-auto text-center text-white">
        <p>&copy; {currentYear} Mon Compost. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
