import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Remplacer les icônes par défaut de Leaflet
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Configuration des options d'icône pour Leaflet
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Définir le type pour un marqueur
interface MarkerData {
  position: [number, number];
  description: string;
}

const MyMap: React.FC = () => {
  // Utiliser le type MarkerData pour le tableau de marqueurs
  const [markers, setMarkers] = useState<MarkerData[]>([]);

  // Récupération des données de marqueurs (simulée ici pour l'exemple)
  useEffect(() => {
    const fetchMarkers = async () => {
      // Simulation de données provenant d'une API
      const data: MarkerData[] = [
        {
          description: "Composteur de la place",
          position: [43.955192, 6.893437],
        },
        {
          description: "Composteur de la médiathèque",
          position: [43.956369, 6.895606],
        },
      ];
      setMarkers(data); // Mettre à jour l'état avec les données récupérées
    };

    fetchMarkers();
  }, []);

  return (
    <MapContainer
      center={[43.955192, 6.893437]}
      zoom={14}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Générer dynamiquement les marqueurs avec TypeScript */}
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Popup>{marker.description}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MyMap;
