const NavbarComponent: React.FC = () => {
  return (
    <nav className="bg-primary p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-white text-xl font-bold">Mon Compost</h1>
        <ul className="flex space-x-4">
          <li>
            <a href="/" className="text-white hover:text-gray-200">
              Home
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarComponent;
